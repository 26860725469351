import { type InteractionColors, type InteractionType } from '@components/Presentation/types';

export function computeLuminance(color: `#${string}`, threshold: number = 0.5) {
  let backgroundColor = color as string;
  // Remove the hash symbol if present
  if (!color?.startsWith('#')) {
    console.error('Invalid color format', color);
    return { isBgLight: false };
  }
  backgroundColor = backgroundColor.slice(1);

  // Convert short form (#fff) to long form (#ffffff)
  if (backgroundColor.length === 3) {
    backgroundColor = backgroundColor.split('').map(char => char + char).join('');
  }

  // Extract RGB values
  const r = parseInt(backgroundColor.slice(0, 2), 16);
  const g = parseInt(backgroundColor.slice(2, 4), 16);
  const b = parseInt(backgroundColor.slice(4, 6), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return true or false based on luminance
  return { isBgLight: luminance > threshold };
};

export function getInteractionBgColorKey(interactionType: InteractionType): keyof InteractionColors | null {
  switch (interactionType) {
    case 'pollWithOptions': return 'pollBgColor';
    case 'freeForm': return 'pollBgColor';
    case 'wordCloud': return 'wcBgColor';
    case 'wheel': return 'choiceCircleBgColor';
    case 'emojisEverywhere': return 'emojisEverywhereBgColor';
    case 'talkingTiles': return 'talkingTilesBgColor';
    case 'transientThought': return 'ttBgColor';
    case 'newWheel': return 'winningWheelBgColor';
    case 'linkLibrary': return 'linkLibraryBgColor';
    case 'Quick Questions': return 'quickQuestionBgColor';
    case 'textTrack': return 'textTrackBgColor';
    case 'ratingPolls': return 'ratingPollsBgColor';
    case 'fantasticFans': return 'ffBgColor';
    case 'map': return 'mapBgColor';
    case 'multipleChoice': return 'multipleChoiceBgColor';
    default:
      return null;
  }
};

export type InteractionKeys = Exclude<InteractionType, 'PDF Slide'>

type InteractionColorMap = {
  bg: Record<InteractionKeys, Partial<keyof InteractionColors>>
  heading: Record<InteractionKeys, Partial<keyof InteractionColors>>
}

export const interactionColorMap: InteractionColorMap = {
  bg: {
    emojisEverywhere: 'emojisEverywhereBgColor',
    pollWithOptions: 'pollBgColor',
    freeForm: 'pollBgColor',
    wordCloud: 'wcBgColor',
    wheel: 'choiceCircleBgColor',
    talkingTiles: 'talkingTilesBgColor',
    transientThought: 'ttBgColor',
    newWheel: 'winningWheelBgColor',
    linkLibrary: 'linkLibraryBgColor',
    'Quick Questions': 'quickQuestionBgColor',
    textTrack: 'textTrackBgColor',
    fantasticFans: 'ffBgColor',
    ratingPolls: 'ratingPollsBgColor',
    map: 'mapBgColor',
    multipleChoice: 'multipleChoiceBgColor'
  },
  heading: {
    pollWithOptions: 'pollWithOptionsHeadingColor',
    freeForm: 'pollHeadingColor',
    wordCloud: 'wcHeadingColor',
    wheel: 'choiceCircleHeadingColor',
    talkingTiles: 'talkingTilesHeadingColor',
    transientThought: 'ttHeadingColor',
    newWheel: 'winningWheelHeadingColor',
    linkLibrary: 'linkLibraryHeadingColor',
    'Quick Questions': 'quickQuestionHeadingColor',
    textTrack: 'textTrackHeadingColor',
    fantasticFans: 'ffHeadingColor',
    ratingPolls: 'ratingPollsHeadingColor',
    map: 'mapHeadingColor',
    emojisEverywhere: 'emojisEverywhereHeadingColor',
    multipleChoice: 'multipleChoiceHeadingColor'
  }
};

export function compareAspectRatioWith16x9or4x3({ width, height }: { width: number, height: number }, layoutMode: 'rectangle' | 'square') {
  if (layoutMode === 'square') return width > height;
  const aspectRatio = width / height;
  return aspectRatio - (16 / 9) > 0; // true for ultrawide \ false for horizonally wide screens
};
